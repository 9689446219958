@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

$font-default: 'Roboto', sans-serif;
$font-heading: 'Roboto Slab', sans-serif; 


 
$font-size:16px;
$font-size-large:18px;  
$font-size-heading:30px; 
$font-size-heading-h1:30px;  
$font-size-banner:30px;  

//Website colors
$white:  	#fff;
$black: 	#333333;
$grey: 		#3f3f3f;
$green: 	#669900;
$grey-dark: #666666;
$grey-light:#f5f5f5;


//Social media
$color-facebook: #3b5998;
$color-twitter: #3b5998;
$color-youtube: #bb0000;
$color-linkedin: #007bb5;
$color-instagram: #125688;
$color-whatsapp: #4dc247;

$border-radius: 5px;