.project{
	margin-bottom:30px;
	a{
		figure{
			position:relative;
			.photo{
				position:relative;
				z-index: 10;
				width:100%;
				height:auto;
			}
			.logo{
				position:absolute;
				z-index:11;
				left:50%;
				top:50%;
				transform:translate(-50%,-50%);
				width:80px;
				height:80px;
				background:$green;
				transition:all .4s ease-in-out .2s;
				&:before{
					position:absolute;
					z-index:11;
					left:50%;
					top:50%;
					transform:translate(-50%,-50%);
					color:$white; 
					font-size:$font-size-heading;
				} 
			}
		}

		.title{
			position: relative;
			padding:40px 0;
			background:$grey-light;
			text-align: center;
			&:before{
				position:absolute;
				left:0;
				bottom:0;
				height:0px;
				width:100%;
				transition:all .4s ease-in-out;
				background:$black;
				content:' ';
			}

			h2{
				position: relative;
				font-size:$font-size-heading;
				font-weight: 800;
				color:$black;
				margin:0 0 20px 0;
				padding:0;
				transition:all .4s ease-in-out .2s;
			}
			p{
				position: relative;
				font-size:$font-size;
				color:$black;
				margin:0;
				padding:0;
				transition:all .4s ease-in-out .3s;
			}

			&.title-project{
				padding:0;
				text-align: left;
				clear:left;
				min-height:80px;
				.icon{
					position: relative;
					z-index: 10;
					margin:0 20px 0 0;
					float:left;
					width:80px;
					height:80px;
					background:$green;
					&:before{
						position:absolute;
						z-index:11;
						left:50%;
						top:50%;
						transform:translate(-50%,-50%);
						color:$white; 
						font-size:$font-size-heading;
					} 
				}
				h2{
					font-size:$font-size-large;
					margin:0;
					padding:15px 0 0 0;
				}
				p{
					font-size:$font-size-large;
					margin:0 0 15px 0;
					padding:0;
				}
			}
		}

		&:hover{
			figure{
				.logo{
					background:$black;
				}
			}
			.title{
				&:before{
					height:100%;
				}
				h2,p{
					color:$white;
				}
			}
		}
	}
}