header{
	position:relative;
	z-index:999;
	width:100%;
	background:$white;
	div[class^='col-']{
		position:relative;
		min-height:85px;
	}
	.logo{
		position: absolute;
		z-index:50;
		left:15px;
		top:50%;
		height:40px;
		max-height:68px;
		transform:translateY(-50%);
		img{
			height:100%;
			width:auto;
		}
	}
	.phone{
		position: absolute;
		z-index:40;
		right:80px;
		top:50%; 
		display: inline-block;
		margin-left:15px;
		font-size:$font-size-large;
		transform:translateY(-50%);
		p{
			margin:0;
			a{
				position:relative;
				padding:10px 15px; 
				text-align:center;
				border-radius:45%;
				background:$white;
				color:$black; 
				transition:all .4s ease-in-out;
				&:hover{
					background:$black;
					color:$white;
				}
			}
		}
	}
}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (min-width: $screen-md-min) {
	header{
		height:auto;
		position:fixed;  
		.phone{
			position: relative;
			right:0;
			top:0;
			font-size:14px;
			transform:none;
			p{
				a{
					padding:10px 10px 10px 45px;
					border-radius:0;
					border-top-left-radius: 21px;
					border-bottom-left-radius: 21px;
					background:#eeeeee;
					&:before{
						position:absolute;
						left:15px;
						top:50%;
						font-family: fontawesome;
						content:'\f095';
						transform:translateY(-50%);
						color:$green;
					}
				}
			}
		}
	}
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {
	header{
		.logo{ 
			height:40%;
		} 
	}
}

// ***************************************************************** 
// LG
// ***************************************************************** 
@media (min-width: $screen-lg-min) {
	header{
		 .logo{ 
			height:60%;
		}
		.phone{
			font-size:$font-size-large;
		}
	}
}
