#homepage{
	.services{
		background:$grey-light;
		padding-top:70px;
		padding-bottom:40px;
		.project{
			a{
				.title{
					background:$white;
				}
			}
		}

		.content-wrapper {
            padding: 30px 30px 0 0;
            
            a {
                margin-bottom: 25px;
            }
        }

		.image-wrapper {
            img {
                width: 100%;
                max-width: 570px;
                box-shadow: 20px 25px 30px 0px rgba(0, 0, 0, 0.1);
            }
        }
	}

	.content{
		padding-top:70px;
		padding-bottom:70px;
		h1{
			margin-bottom:45px;
		}
	}

	.projecten{
		background:$grey-light;
		padding-top:70px;
		padding-bottom:70px;
		h2{
			margin-bottom:30px;
			color:$black;
			font-weight: 800;
		}
		.btn{
			margin:30px 0 0 0;
		}
		.project{
			h2{
				margin-bottom:5px;
			}
			a{
				.title{
					background:$white;
				}
			}
			&:hover{ 
				.title{ 
					h2,p{
						color:$white;
					}
				}
			}
		}
	}
}