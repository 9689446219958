*{
    text-decoration:none;
    outline: none;
}

body {  
    font-family: $font-default;
    font-size: $font-size;
    font-weight:400;
    color:$black;
    line-height: 1.5em; 
}

p{
    font-family: inherit;
    font-size:inherit;
    line-height:1.7em;
    margin:0 0 15px 0;
    padding:0;
}

ul,ol{
    list-style: none;
    margin:0 0 15px 0;
    padding:0;
    li{
        
    }
}

blockquote{
    margin:30px 0;
    padding:30px;
    background:$grey-dark;
    font-family: $font-heading;
    font-size:$font-size-heading;
    color:$white;
    text-align: center;
    line-height:1.5em;
    border:none;
}

a{
    text-decoration: none;
    transition:all 0.5s ease-in-out 0s;
    &:hover, &:focus { 
        text-decoration: none; 
    }
}

h1,h2,h3,h4,h5,h6{ 
    font-family: $font-heading;
    font-weight:800;
    font-size:$font-size-heading;
    line-height: 1.4em; 
    margin:0 0 15px 0;
    padding:0; 
}
    h1{
        font-size:$font-size-heading-h1;
    }
    h2{
        font-weight:400;
        color:$green;
       
    }
    h3,h4,h5,h6{ 
        margin-bottom: 0px; 
    }

input,select,button{
    font-family: inherit;
    font-size:inherit;
    line-height: normal; 
    margin:0;
    padding:0;
}

.btn {
    position: relative;
    display: inline-block;
    background: none;
    border: 1px solid #000;
    color: #000;
    padding: 15px 45px 15px 45px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: all 200ms ease;
    text-transform: uppercase;
    border-radius: 0;

    &:hover {
        background: #000;
        color: #FFF;
        border: 1px solid #fff;
    }
    
    &.btn-line {
        background: none;
        border: 1px solid #CCC;
    }
}

.btn2{
    padding:10px 20px;
    display:inline-block;
    background:$green;
    color:$white;
    transition:all .4s ease-in-out;
    font-family: $font-heading;
    font-size: $font-size-large;
    border-radius:0px; 
    &:hover{
        background:$black;
    }
    &.btn-black{
        background:$black;
        &:hover{
            background:$green;
        }
    }
    &.btn-car{
        position:relative;
        margin:0 0 45px 0;
        padding:0 15px 0 60px;
        border:none;
        background:$grey-dark;
        line-height:45px;
        color:$white;
        text-decoration: none;
        &:before{
            position:absolute;
            left:0;
            top:0;
            width:45px;
            height:45px;
            text-align: center;
            line-height:45px;
            font-family: fontawesome;
            content:'\f1b9';
            background:$green;
            color:$white;
        }
    }
}

.green{
    color:$green;
}


.facebook{
    background:$color-facebook;
}
.twitter{
    background: $color-twitter;
}
.youtube{
    background: $color-youtube;
}
.linkedin{
    background: $color-linkedin;
}
.instagram{
    background: $color-instagram;
}

@media (min-width: $screen-sm-min) {
    header {
        > .container {
            width: 768px;
        }
    }
}

@media (min-width: $screen-md-min) {
    header {
        > .container {
            width: $container-md;
        }
    }
}

@media (min-width: $screen-lg-min) {
    header {
        > .container {
            width: $container-lg;
        }
    }
}