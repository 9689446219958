nav{ 
	background:$white;
	display: inline-block;
	ul#menu{
		margin:0;
		padding:0;
		list-style: none;
		li{
			position:relative;
			display:inline-block;
			font:$font-default;
			font-weight:500;
			font-size:14px;
			white-space: nowrap;
			text-transform: uppercase;
			letter-spacing: 1px;

			&:hover{
				ul{ 
					display: block;
				}
			} 

			

			a{
				display:block;
				padding: 30px 5px;
				color:$black;
				transition:all 400ms ease-in-out;
				&:hover{ 
					color:$green;
					&.active{
						color:$green;
					}
				}
				// &.active{
				// 	background:$green;
				// 	color:$white;
				// }
			} 
			ul{
				position:absolute;
				display:none;
				left:0;
				top:84px;
				margin:0;
				padding:2px;
				background:$white;
				min-width:100%;
				li{
					display:block;
					font-size:14px;
					white-space: nowrap;
					text-align:left;
					a{
						padding:10px 20px;
						&:hover{
							color:$green;
							background:$grey-light;
						}
						// &.active{
						// 	background:$green;
						// 	color:$white;
						// }
					}
				}
			}
			
		}
	}
}

//
// MOBILE NAV
//
#mobilenav{
	position:absolute;
	z-index:30;
	width:100%;
	right:0;
	top:20px;
	background:$white;
	.slicknav_menu{
		background:$white;
		padding:0;
		text-align:left;
		.slicknav_btn{  
			height: 45px;
		    width: 45px;
		    margin: 0 15px 18px 0;
		    border-radius:0px;
            .slicknav_icon {
            	height:11px;
            	padding:5px 3px;
                .slicknav_icon-bar {
                   width: 20px;
                   height: 3px;
                   margin: 3.5px 0; 
                   box-shadow:none; 
                }
            }
		}
		ul.slicknav_nav{  
			padding:15px 0;
			margin:0;
			overflow:scroll;
			max-height:calc(100vh - 90px);
			background:$grey-dark;
			li{
				font-size:$font-size;
				font-family: $font-default;
				padding:5px 0;
				&:hover{
					a{
						background:none;
						 
					}
					ul li a{
						background:none;
					}
				}
				a.active,
				&:hover a:hover{
					 
				}
				a{
					.icon{
						display:none;
					}
				}
				ul {
					padding:0;
					margin:0 0 0 15px;
					li{
						margin:0; 
						a{
							 
							
						}
						ul{
							li{
								a{
								 
								}
							}
						}
					}
				}
			}
		}
	}
}
 
// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {
	nav{  
		ul#menu{ 
			li{  
				a{ 
					padding: 30px 7px; 
				} 
			}
		}
	}
}

// ***************************************************************** 
// LG
// ***************************************************************** 
@media (min-width: $screen-lg-min) {
	nav{  
		ul#menu{ 
			li{ 
				font-size:16px;
				a{ 
					padding: 45px 15px; 
				} 
				ul{
					top:114px;
				}
			}
		}
	}
}
