aside{
	.aside-content{
		margin:0 0 15px 0;
		padding:30px;
		background:#92816d;
		h2,h3,p,label{
			color:$white;
		}
		.btn{
			background:#fff;
		}
	} 

	.banner{
		margin:0 0 15px 0;
		h3{
			display:none;
		}
	} 

}