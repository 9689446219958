footer {
	background: #e9e9e9; 
	background-size: cover;
	background-position: center;
	padding-top: 60px;
	padding-bottom: 30px;
  
	.content-footer-wrapper-left {
	  text-align: center;
  
	  img {
		&.logo {
		  max-height: 75px;
		}
  
	  }
  
	}
  
	.content-footer-wrapper-middle {
	  margin-top: 15px;
	  text-align: center;
  
	  .icons {
		margin: 0;
		padding: 0;
		//font-size: 18px;
		color: #000;
  
		li {
		  display: block;
		  list-style: none;
		  margin-right: 25px;
		  }
		  a{
			color: #000;
		  }
	  }
	}
  
	.content-footer-wrapper-right {
	  color: #000;
	  text-align: center;
	  margin-top: 15px;
  
	  ul {
		margin-right: 15px;
  
		&.icons {
		  margin-right: 10px;
		  margin-top: 40px;
		  margin-bottom: 40px;
		  color: #fff;
		  font-size: 16px;
  
		  li {
			margin-right: 25px;
		  }
		}
  
		li {
		  display: inline-block;
  
		  a {
			font-size: 16px;
			color: #afafaf;
		  }
		}
	  }
	}
  }
  
  @media (min-width: $screen-md-min) {
	footer {
	  .content-footer-wrapper {
		ul {
		  margin-right: 15px;
		}
	  }
	  .content-footer-wrapper-left {
		text-align: left;
	  }
  
	  .content-footer-wrapper-middle {
  
		margin-top: 30px;
		.icons {
  
		  li {
			display: inline-block;
		  }
		}
	  }
  
	  .content-footer-wrapper-right {
		margin-top: 30px;
		text-align: right;
	  }
	}
  }