#news{
 	 
	.locatie{
		display: block;
		background:$grey-light;
		margin:0 0 30px 0;
		.icon{
			position:relative;
			float:left;
			width:45px;
			height:45px;
			background-color: $green;
			margin-right:15px;
			&:before{
				position:absolute;
				left:50%;
				top:50%;
				transform:translate(-50%,-50%);
				color:$white;
			}
		}
		p{	
			display:inline-block;
			margin:0;
			padding:0; 
			line-height:45px;
			font-weight:bold;
		}
	}

	ul.files{
		margin:0;
		padding:15px 0 0 0;
		li{
			position:relative;
			padding:5px 0 5px 20px;
			&:before{
				position:absolute;
				top:5px;
				left:0;
				font-family: fontawesome;
				content:'\f1c1';
			}
			a{
				color:$green; 
				&:hover{
					color:$black;
				}
			}
		}
	}

	.galleries{
		padding-top:45px;
	}

	.addthis{
		margin-top:30px;
		display: block;
		background:$grey-light; 
		.icon{
			position:relative;
			float:left;
			width:51px;
			height:51px;
			background-color: $green; 
			&:before{
				position:absolute;
				left:50%;
				top:50%;
				transform:translate(-50%,-50%);
				color:$white;
				font-family: fontawesome;
				font-size:$font-size-large;
				content:'\f045';
			}
		}
		.addthis_inline_share_toolbox{
			display:inline-block;
			margin:0;
			padding:7px 0 7px 20px; 
			line-height:60px; 
			.at-resp-share-element .at-share-btn {
				margin:0 10px 0 0;
				padding:3px;
			}

		}
	}
}