.content{
	position: relative;
	z-index:10;
	padding-top:30px;
	padding-bottom:30px;
	h1{
		margin-bottom:45px;
	} 

	

	a{
		color:$green;
		text-decoration:underline;
		&:hover{
			color:$black;
		}
	}
	img{
		max-width:100%;
		height:auto;
	}

	ul{

		li{
			position:relative;
			padding:5px 0 5px 20px;
			&:before{
				position:absolute;
				left:0;
				top:5px;
				content:'\f0da';
				font-family: fontawesome;
			}
		}
	}

	.links{
		p{
			font-size: 13px;
			text-align: center;
			
		}
	}
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {
	.content{
		padding-top:45px;
		padding-bottom:45px;
	}
}

// ***************************************************************** 
// LG
// ***************************************************************** 
@media (min-width: $screen-lg-min) {
	.content{
		padding-top:60px;
		padding-bottom:60px;
	}
}

