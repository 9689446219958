.banner{
	position: relative;
	img{
		position: relative;
		z-index:10;
		width:100%;
		height:auto;
	}
	h3{
		position:absolute;
		z-index: 11;
		margin:0 auto;
		padding:0; 
		top:0;
		left:50%;
		transform:translateX(-50%);
		width:100%;
		height:100%;
		transition:all .4s ease-in-out;
		a{
			display:block;
			position:absolute;
			z-index:11;
			left:0;
			top:50%;
			transform:translateY(-50%);
			width:60%;
			padding:20px; 
			background:rgba($green ,.8);
			transition:all .4s ease-in-out;
			display:block;
			color:$white;
			font-family: $font-default;
			font-size: $font-size;
			font-weight:400;
			line-height: 1.3em;
			transition:all .4s ease-in-out;
			&:hover{ 
				background:rgba($white ,.8);
				color:$green; 
			}
		}
	}
}


// ***************************************************************** 
// SM
// ***************************************************************** 
@media (min-width: $screen-sm-min) {
	.banner{
		h3{ 
			a{
				padding:30px;  
				font-size: $font-size-heading; 
			}
		}
	}
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {
	.banner{
		h3{
			top:20%;
			height:60%;
			max-width:970px;
			a{
				top:20%;
				padding:40px; 
				max-width:500px;
				font-size: $font-size-banner;
				transform:translateY(-20%);
			}
		}
	}
}

// ***************************************************************** 
// LG
// ***************************************************************** 
@media (min-width: $screen-lg-min) {
	.banner{
		h3{
			max-width:1440px;
			a{
				max-width:300px;
			}
		}
	}
}