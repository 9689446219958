.partners{
	padding-top:60px;
	padding-bottom:60px;
	.slick-slide {
		text-align:center;
		a{
			display:inline-block;
			img{
				filter: contrast(100%);
				transition:all .4s ease-in-out;
			}
			&:hover{
				img{
					filter: contrast(75%);
				}
			}
		}
	}
}