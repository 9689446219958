.carousel{ 
	position:relative;
	z-index:1;
	.slick-list{ 
		position:relative;
		z-index:20; 
		overflow:hidden;
		.slide{
			position:relative; 
			z-index:21; 
			padding:0;
			width:100%;  
 			height: auto;
 		}
 		.slick-slide{
 			position:relative;
 			img.background{
 				position:relative;
 				z-index:10;
 				width:100%;
 				height:auto;
 			}
 			img.logo{
 				position:absolute;
 				z-index:20;
 				left:50%;
 				top:50%;
 				transform:translate(-50%, -50%);
 			}

			.title{
				position:absolute; 
				background:rgba($black,0.4);
				padding:30px;
				max-width:600px;
				width:35%;

				z-index:22;
				&.top-left{
					top:200;
					left:10%;
					text-align:left;
				}
				&.top-center{
					top:0;
					left:50%;
					transform: translateX(-50%);
					text-align:center;
				}
				&.top-right{
					top:0;
					right:10%;
					text-align:right;
				}
				&.middle-left{
					top:50%;
					left:10%;
					transform: translateY(-50%);
					text-align:left;
				}
				&.middle-center{
					left:50%;
					top:50%;
					transform: translate(-50%,-50%);
					text-align:center;
				}
				&.middle-right{
					right:10%;
					top:50%;
					transform: translateY(-50%);
					text-align:right;
				}
				&.bottom-left{
					left:10%;
					bottom:0;
					text-align:left;
					
				}
				&.bottom-center{
					left:50%;
					bottom:0;
					transform: translateX(-50%);
					text-align:center;
				}
				&.bottom-right{
					bottom:0;
					right:10%;
					text-align:right;
				}
				h2,p{
					text-align:inherit;
					&.black{
						color:#000;
						a{
							color:inherit;
						}
					}
					&.grey-white{
						color:#fff;
						a{
							color:inherit;
						}
					}
				}
				h2{
					font-family: $font-heading;
					font-size: 40px;
					margin:0;
					padding:0;
					line-height:normal; 
					a:hover{
						filter:brightness(80%);
					}
				}
				p{
					font-size: $font-size;
					margin:0;
					padding:15px 0 0 0;
					line-height:normal;
				}
				a{
					text-decoration:none;
					h2,p{
						&.black{
						color:#000;
						}
						&.grey-white{
							color:#fff;
						}
					}
				}
			}
			
		}
		
	}
	button{
		position:absolute;
		z-index:21;
		top:50%;
		margin-top:-10px; 
		background:none;
		border:none;
		color:#fff;
		font-size:80px;
		&.slick-prev{
			left:15px;
		}
		&.slick-next{
			right:15px;
		}
	} 

	.slick-dots{
        position:absolute; 
        z-index:21;
        bottom:0px;
        width:100%; 
        text-align:center;
        margin:0;
        padding:15px 0;
        // background:rgba($grey-light,.5);
        li{
        	list-style:none;
        	display:inline-block; 
            width:8px;
            height:8px;
            line-height:12px;
            cursor:pointer;
            margin:0 5px;
            border-radius:50%;
            border:none;
            background:$grey-light;
             
            &.slick-active{
                background:$black;
            }
            button{
            	display:none;
            }
            button::before{
                content:" ";
            }
        }
    }

}


// ***************************************************************** 
// MD
// ***************************************************************** 
@media (max-width: $screen-lg-min) {

	.carousel{  
		.slick-list{  
		 	.slick-slide{
				.title{
		 			padding:20px;
		 			h2{
						font-size: $font-size-heading;
					} 
		 		}
	 		}
		}
	} 

}


// ***************************************************************** 
// SM
// ***************************************************************** 
@media (max-width: $screen-md-min) {

	.carousel .slick-list{
		.title{
			display:none;
		}  
		.slide{  
			height: 200px; 
		}
	}
} 
