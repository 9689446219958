.pagination {
	> li:first-child > a, 
	> li:first-child > span,
	> li:last-child > a, 
	> li:last-child > span {
		border-radius:0px;
	}
	
	li{ 
		span{
		 	color:$black;
		}

		a{	
			border:1px solid $white;
			color:$black;
		}

		&:first-child,
		&:last-child{ 
			 
			a{
				border:1px solid $grey-light;
			}
		}

		&:hover{
			a{
				border:1px solid $grey-light;
				background:$grey-light;
			}
		}

		&.active,
		&.active:hover{
			span{
				background: $green;
				border:1px solid $green;
				color:$white;
			}
		}

	}
}

