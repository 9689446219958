form{
	input,select,textarea{
		font-family: $font-default;
    	font-size: $font-size;
	}
	
	input#image{
		padding:0;
		border:none;
		height:40px;
		line-height:34px;
		box-shadow: none;
	}

	input[type='submit'],
	button{
		border:none;
		
		&:hover {
			background: #000;
			color: #fff;
			border: 1px solid #fff;
		}
	}
}