@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon/icomoon.eot?wfskcu');
  src:  url('../../fonts/icomoon/icomoon.eot?wfskcu#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon/icomoon.ttf?wfskcu') format('truetype'),
    url('../../fonts/icomoon/icomoon.woff?wfskcu') format('woff'),
    url('../../fonts/icomoon/icomoon.svg?wfskcu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal; 
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e904";
}
.icon-menu:before {
  content: "\e905";
}
.icon-onderhoud:before {
  content: "\e906";
}
.icon-phone:before {
  content: "\e907";
}
.icon-tuinaanleg:before {
  content: "\e908";
}
.icon-tuinontwerp:before {
  content: "\e909";
}
.icon-youtube:before {
  content: "\e90a";
}
.icon-vhg:before {
  content: "\e900";
}
.icon-alles-in-huis:before {
  content: "\e901";
}
.icon-aluxe:before {
  content: "\e902";
}
.icon-tuinvisie:before {
  content: "\e903";  
}

