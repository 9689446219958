.galleries{
	.photo{
		margin-bottom:30px;
		a{
			position: relative;
			z-index: 40;
			display:block;
			border:2px solid $grey-light;
			transition:all .4s ease-in-out;
			&:before{
				position: absolute;
				z-index: 60;
				left:50%;
				top:50%;
				transform:translate(-50%,-50%);
				font-family: fontawesome;
				content:'\f002';
				font-size:$font-size-banner;
				color:$white; 
				opacity:0;
				transition:all .4s ease-in-out;
			}
			img{
				width:100%;
				height:auto;
				position: relative;
				z-index: 50;
				filter:blur(.4px);
				transition:all .4s ease-in-out;
				filter: grayscale(100%);
			}

			&:hover{
				border:2px solid $grey-dark;
				&:before{
					opacity:1;
				}
				img{
					filter:blur(0px);
					filter: grayscale(0);
				} 
			}
		}
	}
}